import React from "react";

const Tech = () => {
  return (
    <div className="lg:w-[86%] w-full mx-auto">

      <div className="flex flex-col gap-y-6 ">
        <div className="sm:flex gap-x-6 items-start">
          <p className="font-bold flex gap-x-2">
            <span className="text-xl">Technologies</span> <span>:</span>
          </p>
          <div className="ml-10 sm:ml-0 flex flex-wrap gap-x-6">
            <p>• Zig</p>
            <p>• Rust</p>
            <p>• JavaScript</p>
            <p>• TypeScript</p>
            <p> | </p>

            <p>• Puppeteer</p>
            <p>• Tauri</p>
            <p>• Actix</p>
            <p>• Vite</p>
            <p>• React.Js</p>
            <p>• Next.Js</p>
            <p>• React Native,</p>
            <p>• Redux</p>
            <p>• Node Js</p>
            <p>• Express JS</p>
            <p>• EJS</p>
            <p> | </p>

            <p>• CSS</p>
            <p>• SCSS</p>
            <p>• Tailwind CSS</p>
            <p>• Bootstrap</p>
            <p>• GSAP</p>
            {/* <p>• Framer Motion</p> */}
            <p> | </p>

            {/* <p>• SSL Commerz</p>
            <p>• Stripe</p>
            <p> | </p> */}

            <p>• MongoDB</p>
            <p>• SQLite</p>
            <p>• Firebase</p>
            <p>• Docker</p>

            <p> etc...</p>
          </div>
        </div>

        <div className="sm:flex gap-x-7 items-start">
          <p className="font-bold flex gap-x-2">
            <span className="text-xl">Tools</span> <span>:</span>
          </p>
          <p className="lg:ml-[70px] ml-10 sm:ml-0 flex flex-wrap gap-x-6">
            <span>• Git & GitHub</span>
            <span>• Figma</span>
            <span>• Adobe XD</span>
            <span>• Adobe Illustrator</span>
            <span>• Adobe Photoshop</span>
            <span>• Adobe After Effects</span>
            <span>• Notion</span>
            <span>• Jira</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tech;
